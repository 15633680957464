<template>
  <div class="mb-4 mt-3">
    <v-row dense class="mt-2" justify="center" align="center">
      <v-col>
        <v-badge
          class="mr-4"
          v-if="insumosNaoConfigurados != null"
          overlap
          right
          :color="insumosNaoConfigurados > 0 ? 'primary' : 'transparent'"
          :content="insumosNaoConfigurados"
        >
          <v-btn
            :outlined="getInsumosNaoConfiguradosFilter ? false : true"
            color="primary"
            @click="changeFilterToNaoConfigurados()"
          >
            Insumos
          </v-btn>
        </v-badge>
        <v-skeleton-loader v-else type="button" align="center" />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <default-text-field label="SKU" v-model="idInsumo" />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <default-text-field label="Descrição" v-model="nomeInsumo" />
      </v-col>

      <v-col cols="12" sm="4" md="2">
        <default-filter-button
          :loading="isButtonLoading"
          @filter="filter"
          @cleanFilter="cleanFilter"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultFilterButton from "@/shared/components/vuetify/DefaultFilterButton.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ConfiguracaoDeInsumoHeader",
  components: {
    DefaultFilterButton,
    DefaultTextField,
  },
  data: () => ({
    idInsumo: "",
    nomeInsumo: "",
    isButtonLoading: false,
  }),
  methods: {
    ...mapActions("insumos", [
      "insumos",
      "setListingInsumosNaoConfiguradosFilter",
      "setUrlParams",
    ]),

    filter() {
      const body = this.getUrlParams;
      body.nome = this.nomeInsumo;
      body.idInsumo = this.idInsumo;
      console.log(body);
      this.setUrlParams(body);
      this.$emit("filter", body);
    },
    cleanFilter() {
      this.idInsumo = "";
      this.nomeInsumo = "";
    },

    changeFilterToNaoConfigurados() {
      const value = this.getInsumosNaoConfiguradosFilter ? false : true;
      this.setListingInsumosNaoConfiguradosFilter(value);
    },
  },
  props: {
    insumosNaoConfigurados: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("insumos", [
      "getInsumosNaoConfiguradosFilter",
      "getUrlParams",
    ]),
  },
};
</script>
<style></style>
