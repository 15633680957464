var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{ref:"form",attrs:{"data-vuetify":""}},[_c('v-card-title',{staticClass:"justify-center navy--text",staticStyle:{"text-transform":"none !important","font-weight":"bolder"}},[_c('h3',{},[_vm._v("Editar Insumo")])]),(!_vm.isLoadingModal)?_c('div',[_c('v-card',{staticClass:"pb-0"},[_c('v-container',[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('default-text-field',{attrs:{"disabled":"","label":"Nome Insumo"},model:{value:(_vm.editInsumo.nomeInsumo),callback:function ($$v) {_vm.$set(_vm.editInsumo, "nomeInsumo", $$v)},expression:"editInsumo.nomeInsumo"}})],1)],1),_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-5",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-body-2"},[_vm._v("Ativar / Desativar Esteira")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('default-switch',{attrs:{"inputValue":_vm.editInsumo.esteira},model:{value:(_vm.editInsumo.esteira),callback:function ($$v) {_vm.$set(_vm.editInsumo, "esteira", $$v)},expression:"editInsumo.esteira"}})],1)],1),_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-5",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-body-2"},[_vm._v("Ativar / Desativar Seladora")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('default-switch',{attrs:{"inputValue":_vm.editInsumo.seladora},model:{value:(_vm.editInsumo.seladora),callback:function ($$v) {_vm.$set(_vm.editInsumo, "seladora", $$v)},expression:"editInsumo.seladora"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{staticClass:"text-truncate",class:_vm.isTransportadora
                    ? hover
                      ? 'primary--text'
                      : 'base--text'
                    : hover
                    ? 'base--text'
                    : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"loading":_vm.isLoadingBtn,"color":_vm.isTransportadora ? 'primaryTransportadora' : 'primary',"rounded":"","block":""},on:{"click":function($event){return _vm.insumoEdit(_vm.insumo)}}},[_vm._v("Editar")])]}}],null,false,2027888911)})],1)],1)],1)],1)],1):_c('v-col',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }