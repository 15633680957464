<template>
  <v-container fluid>
    <div>
      <configuracao-de-insumo-header
        :insumosNaoConfigurados="getInsumosNaoConfigurados ?? 0"
        @filter="filter"
      />
      <configuracao-de-insumo-list
        :insumos="getListingInsumos"
        :pagination="getInsumosPagination"
        :loading="getIsLoading"
        @paginate="proxPage"
      />
    </div>
  </v-container>
</template>
<script>
import ConfiguracaoDeInsumoHeader from "../components/ConfiguracaoDeInsumoHeader";
import ConfiguracaoDeInsumoList from "../components/ConfiguracaoDeInsumoList";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ConfiguracaoDeInsumos",
  components: {
    ConfiguracaoDeInsumoHeader,
    ConfiguracaoDeInsumoList,
  },
  data: () => ({}),
  mounted() {
    this.insumos();
  },
  methods: {
    ...mapActions("insumos", ["insumos", "setIsLoading", "setUrlParams"]),
    async getListInsumos() {
      await this.insumos();
    },
    async proxPage(page) {
      this.setIsLoading(true);
      let tempUrl = this.getUrlParams;
      this.urlParams = tempUrl;
      this.urlParams.page = page;
      await this.setUrlParams(this.urlParams);
      await this.getListInsumos();
      this.setIsLoading(false);
    },
    async paginate(pagination) {
      this.page = pagination;
      this.getListInsumos();
    },
    async filter(filter) {
      console.log(filter);
      this.setUrlParams(filter);
      await this.getListInsumos();
    },
  },
  computed: {
    ...mapGetters("insumos", [
      "getListingInsumos",
      "getInsumosPagination",
      "getInsumosNaoConfigurados",
      "getIsLoading",
      "getUrlParams",
    ]),
  },
};
</script>
<style></style>
