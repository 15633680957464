<template>
  <v-card ref="form" data-vuetify>
    <v-card-title
      class="justify-center navy--text"
      style="text-transform: none !important; font-weight: bolder"
    >
      <h3 class="">Editar Insumo</h3>
    </v-card-title>
    <div v-if="!isLoadingModal">
      <v-card class="pb-0">
        <v-container>
          <v-row no-gutters class="justify-center">
            <v-col cols="11">
              <default-text-field
                disabled
                label="Nome Insumo"
                v-model="editInsumo.nomeInsumo"
              />
            </v-col>
          </v-row>

          <v-row no-gutters class="justify-center">
            <v-col cols="4" class="my-5">
              <div class="text-body-2">Ativar / Desativar Esteira</div>
            </v-col>
            <v-col cols="2">
              <default-switch
                v-model="editInsumo.esteira"
                :inputValue="editInsumo.esteira"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-center">
            <v-col cols="4" class="my-5">
              <div class="text-body-2">Ativar / Desativar Seladora</div>
            </v-col>
            <v-col cols="2">
              <default-switch
                v-model="editInsumo.seladora"
                :inputValue="editInsumo.seladora"
              />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="6">
              <v-hover v-slot="{ hover }">
                <v-btn
                  :loading="isLoadingBtn"
                  :color="
                    isTransportadora ? 'primaryTransportadora' : 'primary'
                  "
                  class="text-truncate"
                  :class="
                    isTransportadora
                      ? hover
                        ? 'primary--text'
                        : 'base--text'
                      : hover
                      ? 'base--text'
                      : 'navy--text'
                  "
                  style="text-transform: none !important; font-weight: bolder"
                  @click="insumoEdit(insumo)"
                  rounded
                  block
                  >Editar</v-btn
                >
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import DefaultTextField from "../../../shared/components/vuetify/DefaultTextField.vue";
import DefaultSwitch from "../../../shared/components/vuetify/DefaultSwitch.vue";
import { mapActions } from "vuex";

export default {
  name: "ConfiguracaoDeInsumoEditModal",
  components: {
    DefaultTextField,
    DefaultSwitch,
  },
  data: () => ({
    reRender: 0,
    editInsumo: {
      seladora: false,
      esteira: false,
    },
    isLoadingModal: false,
    formHasErrors: false,
    isLoadingBtn: false,
    errorMessages: "",
    nomeInsumo: "",
    rules: {
      name: (value) => !!value || "O campo nome não pode ser vazio.",
      company: (value) =>
        !!value || "O campo transportadora não pode ser vazio.",
      cpf: (value) => !!value || "O campo cpf não pode ser vazio.",
    },
  }),
  props: {
    insumo: {
      type: Object,
      required: true,
    },
  },
  watch: {
    insumo: {
      handler(newVal) {
        this.editInsumo.nomeInsumo = newVal.nomeInsumo ?? newVal.descricao;
        this.editInsumo.esteira = newVal.esteira ?? false;
        this.editInsumo.seladora = newVal.seladora ?? false;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("insumos", ["editInsumoPut"]),
    async insumoEdit() {
      this.isLoadingBtn = true;
      const body = {
        tipoInsumoId: this.insumo.insumoId ?? this.insumo.id,
        seladora: this.editInsumo.seladora ?? false,
        esteira: this.editInsumo.esteira ?? false,
      };
      await this.editInsumoPut(body);
      this.isLoadingBtn = false;
      this.$emit("insumos");
      this.$emit("closeButton");
    },
  },
  computed: {
    isTransportadora() {
      return false;
    },
  },
};
</script>
<style></style>
