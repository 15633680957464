<template>
  <div class="d-none d-md-block">
    <default-table-head class="d-none d-lg-flex">
      <v-col cols="2" class="border-right">SKU</v-col>
      <v-col cols="5" class="border-right">Descrição</v-col>
      <!-- <v-col cols="2" class="border-right ">Status</v-col> -->
      <v-col cols="2" class="border-right">Esteira</v-col>
      <v-col cols="2" class="border-right">Seladora</v-col>
    </default-table-head>
    <template v-if="loading">
      <default-spinner class="my-2" />
    </template>
    <div v-else>
      <template>
        <configuracao-de-insumo-listing-card
          v-for="(insumo, index) in insumos"
          :key="index"
          :insumos="insumo"
          @openModal="openModal"
        />
        <default-pagination
          v-model="pagination.currentPage"
          :length="pagination.lastPage"
          @input="paginate(pagination.currentPage)"
          :disabled="insumos.length === 0"
        />
      </template>
    </div>
    <v-dialog v-model="editDialog" max-width="720">
      <configuracao-de-insumo-edit-modal
        :key="reRender"
        :insumo="insumo"
        @closeButton="closeButton"
      />
    </v-dialog>
  </div>
</template>
<script>
import DefaultTableHead from "@/shared/components/vuetify/DefaultTableHead.vue";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import ConfiguracaoDeInsumoListingCard from "./ConfiguracaoDeInsumosListCard.vue";
import ConfiguracaoDeInsumoEditModal from "./ConfiguracaoDeInsumoEditModal.vue";

export default {
  name: "ConfiguracaoDeInsumoList",
  components: {
    DefaultTableHead,
    DefaultPagination,
    DefaultSpinner,
    ConfiguracaoDeInsumoListingCard,
    ConfiguracaoDeInsumoEditModal,
  },
  data: () => ({
    reRender: 0,
    insumo: {},
    editDialog: false,
  }),
  props: {
    insumos: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    paginate(page) {
      this.$emit("paginate", page);
    },
    openModal(insumo) {
      this.insumo = insumo;
      this.reRender += 1;
      this.editDialog = true;
    },
    closeButton() {
      this.editDialog = false;
    },
  },
};
</script>
<style></style>
