<template>
  <default-table-body-row>
    <v-col cols="3" sm="2" md="2">
      <span class="d-block d-md-none font-weight-bold">SKU</span>
      <span class="d-block text-wrap mb-n2" :title="insumos.insumoId">
        {{ insumos.insumoId ?? insumos.codigoProdutoMateriaPrima }}
      </span>
    </v-col>
    <v-col cols="5" sm="5" md="5">
      <span class="d-block d-md-none font-weight-bold">Descrição</span>
      <span
        class="d-block text-wrap font-weight-bold secondary--text mb-n2"
        :title="insumos.nomeInsumo"
      >
        {{ insumos.nomeInsumo ?? insumos.descricao }}
      </span>
    </v-col>
    <!-- <v-col cols="3" sm="2" md="2">
      <span class="d-block d-md-none font-weight-bold">Status</span>
      <span class="d-block text-wrap" :title="insumos.status">
        {{ insumos.status }}
      </span>
    </v-col> -->
    <v-col cols="3" sm="2" md="2">
      <span class="d-block d-md-none font-weight-bold">Esteira</span>
      <v-chip
        class="pa-2 text-wrap"
        text-color="white"
        :class="`${
          insumos.esteira ? 'px-10' : 'px-8 px-md-10'
        } text-wrap text-overline`"
        :color="colorChip(insumos.esteira)"
      >
        {{ insumos.esteira ? "Sim" : "Não" }}
      </v-chip>
    </v-col>
    <v-col cols="3" sm="2" md="2">
      <span class="d-block d-md-none font-weight-bold">Seladora</span>
      <v-chip
        class="pa-2 text-wrap"
        text-color="white"
        :class="`${
          insumos.seladora ? 'px-10' : 'px-8 px-md-10'
        } text-wrap text-overline`"
        :color="colorChip(insumos.seladora)"
      >
        {{ insumos.seladora ? "Sim" : "Não" }}
      </v-chip>
    </v-col>
    <v-col cols="3" sm="1" md="1">
      <default-fab
        tooltip-text="Configurar"
        :loading="loadingButton"
        @click="configurarInsumos(insumos)"
        ><v-icon>mdi-cog-outline</v-icon></default-fab
      >
    </v-col>
  </default-table-body-row>
</template>
<script>
import DefaultTableBodyRow from "../../../shared/components/vuetify/DefaultTableBodyRow.vue";
import DefaultFab from "../../../shared/components/vuetify/DefaultFab.vue";
export default {
  name: "ConfiguracaoDeInsumosListCard",
  components: {
    DefaultTableBodyRow,
    DefaultFab,
  },
  props: {
    insumos: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadingButton: false,
  }),
  methods: {
    colorChip(status) {
      if (status) {
        return "success";
      } else {
        return "red";
      }
    },
    configurarInsumos(insumo) {
      this.$emit("openModal", insumo);
    },
  },
};
</script>
<style scoped>
::v-deep .v-chip__content {
  display: flex;
  justify-content: center;
}
</style>
